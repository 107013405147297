// it is necessary to override MuiTimelineDot
import type {} from '@mui/lab/themeAugmentation';

import {
    createTheme,
    TableRow,
    TableRowProps,
    Theme,
    ThemeOptions,
    ThemeProvider,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { amber, blue, blueGrey, grey, lightBlue, yellow } from '@mui/material/colors';
import { PaletteOptions } from '@mui/material/styles';
import { ReactNode } from 'react';
import { useThemeContext } from './components/layout/ThemeSelector';

export const createCustomTheme = (options?: ThemeOptions): Theme =>
    createTheme({
        ...options,
        palette: {
            footer: {
                background: '#333',
                text: '#fff',
            },
            ...options?.palette,
        },
    });

const spacing = createCustomTheme().spacing;
const lightPalette: PaletteOptions = {
    // from https://material-ui.com/style/color/
    primary: {
        dark: blueGrey[700],
        main: blueGrey[700],
        light: blueGrey[300],
        contrastText: '#fff',
    },
    secondary: {
        main: amber['A700'],
        contrastText: '#fff',
    },
    background: {
        paper: grey[50],
        default: '#fff',
    },
    text: {
        primary: '#000',
        secondary: blueGrey[700],
        disabled: grey[600],
    },
    link: blue[700],
    nav: {
        background: yellow['A400'],
        selected: amber['A200'],
        icon: amber[900],
        text: grey[900],
    },
};

const darkPalette: PaletteOptions = {
    mode: 'dark',
    primary: {
        dark: '#19b9a2',
        main: '#19b9a2',
        light: grey['700'],
        contrastText: '#fff',
    },
    secondary: {
        main: amber['A700'],
        contrastText: '#fff',
    },
    background: {
        paper: '#333',
        default: grey[900],
    },
    text: {
        primary: '#fff',
        secondary: '#19b9a2',
        disabled: grey[500],
    },
    link: lightBlue['A200'],
    nav: {
        background: grey[800],
        icon: amber[800],
        text: '#fff',
        selected: grey[700],
    },
};

export type ColorMode = 'light' | 'dark';

export const getTheme = (colorMode: ColorMode): Theme => {
    const paletteOption = colorMode === 'light' ? lightPalette : darkPalette;
    const theme = createCustomTheme({ palette: paletteOption });
    const palette = theme.palette;
    return createCustomTheme({
        palette: paletteOption,
        breakpoints: {
            ...theme.breakpoints,
            values: {
                // https://mui.com/material-ui/customization/breakpoints/#custom-breakpoints
                xs: 0,
                sm: 600,
                md: 1000,
                lg: 1200,
                xl: 1536,
            },
        },
        typography: {
            fontSize: 12,
            fontFamily: 'Roboto, Noto Sans JP, sans-serif',
            h5: {
                fontWeight: 900,
            },
            button: {
                textTransform: 'none',
                fontWeight: 700,
            },
        },
        components: {
            MuiCard: {
                defaultProps: {
                    elevation: 0,
                    square: true,
                    variant: 'outlined',
                },
                styleOverrides: {
                    root: {
                        marginBottom: spacing(1),
                    },
                },
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: { padding: 0 },
                    title: {
                        marginBottom: spacing(1),
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderColor: palette.primary.light,
                    },
                },
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        padding: spacing(1),
                        '&:last-child': {
                            paddingBottom: spacing(1),
                        },
                    },
                },
            },
            MuiButton: {
                defaultProps: { size: 'small', color: 'primary' },
                styleOverrides: {
                    sizeLarge: {
                        width: 200,
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    root: {
                        width: 28,
                        height: 16,
                        padding: 0,
                        display: 'flex',
                        margin: spacing(0, 0.5),
                        '& .MuiSwitch-switchBase': {
                            padding: 2,
                            '&.Mui-checked': {
                                transform: 'translateX(12px)',
                                color: '#fff',
                                '& + .MuiSwitch-track': {
                                    opacity: 1,
                                },
                            },
                        },
                        '& .MuiSwitch-thumb': {
                            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
                            width: 12,
                            height: 12,
                            borderRadius: 6,
                            transition: theme.transitions.create(['width'], {
                                duration: 200,
                            }),
                        },
                        '& .MuiSwitch-track': {
                            borderRadius: 16 / 2,
                            opacity: 1,
                            backgroundColor:
                                theme.palette.mode === 'dark'
                                    ? 'rgba(255,255,255,.35)'
                                    : 'rgba(0,0,0,.25)',
                            boxSizing: 'border-box',
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    textColorInherit: {
                        '&.Mui-disabled': {
                            opacity: 0.3,
                        },
                    },
                },
            },
            MuiTabs: { defaultProps: { indicatorColor: 'secondary' } },
            MuiTable: { defaultProps: { size: 'small' } },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        whiteSpace: 'nowrap',
                    },
                    head: {
                        color: palette.primary.main,
                    },
                },
            },
            MuiTableSortLabel: {
                styleOverrides: {
                    icon: {
                        width: '0.7rem',
                        height: '0.7rem',
                    },
                },
            },
            MuiChip: {
                defaultProps: { size: 'small' },
                styleOverrides: {
                    root: { fontSize: 11 },
                    sizeSmall: { height: 20, fontWeight: 700 },
                },
            },
            MuiAppBar: { styleOverrides: { root: { margin: 0 } } },
            MuiSvgIcon: { styleOverrides: { fontSizeSmall: { fontSize: 16 } } },
            MuiTextField: {
                defaultProps: { size: 'small' },
                styleOverrides: { root: { backgroundColor: palette.background.paper } },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: { marginRight: spacing(1) },
                },
            },
            MuiAlert: { styleOverrides: { root: { fontSize: 14 } } },
            MuiAlertTitle: { styleOverrides: { root: { marginTop: 0 } } },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: { '&.Mui-expanded': { minHeight: '48px' } },
                    content: { '&.Mui-expanded': { margin: '12px 0' } },
                },
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: { display: 'block' },
                },
            },
            MuiBreadcrumbs: { defaultProps: { separator: '›' } },
            MuiLink: { defaultProps: { underline: 'hover' } },
            MuiTimelineDot: {
                styleOverrides: { root: { boxShadow: 'none' } },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: { fontSize: 11.5, letterSpacing: 0.2 },
                },
            },
            MuiStepLabel: {
                styleOverrides: {
                    label: { fontSize: 13 },
                },
            },
        },
    });
};

export const useMobile = (): boolean => useMediaQuery(createCustomTheme().breakpoints.only('xs'));

export const FormTheme: React.FC<React.PropsWithChildren<unknown>> = (props) => {
    const theme = useTheme();
    const formTheme = createTheme({
        ...theme,
        components: {
            ...theme.components,
            MuiTextField: {
                defaultProps: {
                    size: 'small',
                },
                styleOverrides: {
                    root: { backgroundColor: theme.palette.background.default },
                },
            },
            MuiButton: {
                styleOverrides: {
                    outlined: { backgroundColor: theme.palette.background.default },
                },
            },
        },
    });
    return <ThemeProvider theme={formTheme}>{props.children}</ThemeProvider>;
};

export const DisabledTableRow: React.FC<TableRowProps> = (props) => {
    const theme = useTheme();
    return (
        <TableRow
            {...props}
            sx={{
                backgroundColor: theme.palette.background.paper,
                '& .MuiTableCell-root': {
                    color: theme.palette.text.disabled,
                },
            }}
        />
    );
};

export const ARTICLE_BASE_FONT_SIZE = 14.6;

export const articleHeaderStyles = (theme: Theme) => ({
    h1: {
        margin: theme.spacing(3, 0, 1),
        fontSize: `${ARTICLE_BASE_FONT_SIZE * 2.125}px`,
        fontWeight: 500,
        color: theme.palette.primary.main,
    },
    h2: {
        margin: theme.spacing(3, 0, 1),
        fontSize: `${ARTICLE_BASE_FONT_SIZE * 1.5}px`,
        fontWeight: 500,
        color: theme.palette.primary.main,
        borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
    h3: {
        margin: theme.spacing(2.5, 0, 1),
        fontSize: `${ARTICLE_BASE_FONT_SIZE * 1.25}px`,
        fontWeight: 700,
        color: theme.palette.primary.main,
    },
    h4: {
        margin: theme.spacing(2, 0, 1),
        fontSize: `${ARTICLE_BASE_FONT_SIZE * 1.125}px`,
        fontWeight: 500,
        color: theme.palette.primary.main,
    },
    h5: {
        margin: theme.spacing(1.5, 0, 1),
        fontSize: `${ARTICLE_BASE_FONT_SIZE}px`,
        fontWeight: 500,
        color: theme.palette.primary.main,
    },
    h6: {
        margin: theme.spacing(1.5, 0, 1),
        fontSize: `${ARTICLE_BASE_FONT_SIZE}px`,
        fontWeight: 500,
        color: theme.palette.primary.main,
    },
});

export const ArticleTheme: React.FC<{
    children: ReactNode;
    fontSize?: number;
}> = (props) => {
    const fontSize = props.fontSize ?? ARTICLE_BASE_FONT_SIZE;
    const theme = useTheme();
    const articleTheme = createTheme({
        ...theme,
        typography: {
            ...theme.typography,
            fontSize,
            allVariants: {
                lineHeight: 1.5,
            },
            ...articleHeaderStyles(theme),
            body1: {
                margin: '1rem 0',
                fontSize,
                fontFamily:
                    "Roboto, 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴシック Pro', sans-serif",
                lineHeight: 1.75,
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    pre: {
                        margin: 0,
                    },
                    code: {
                        fontFamily: '"Ubuntu Mono", monospace',
                        fontSize: '0.8rem',
                    },
                },
            },
            MuiPaper: {
                defaultProps: {
                    variant: 'outlined',
                },
                styleOverrides: {
                    root: {
                        padding: theme.spacing(0, 2),
                        backgroundColor: theme.palette.background.paper,
                    },
                },
            },
            MuiTable: {
                defaultProps: {
                    size: 'small',
                },
                styleOverrides: {
                    root: { width: 'auto' },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: theme.palette.divider,
                    },
                    head: {
                        backgroundColor: theme.palette.background.paper,
                    },
                },
            },
        },
    });
    return <ThemeProvider theme={articleTheme}>{props.children}</ThemeProvider>;
};

export const ColorTheme = (props: { children: ReactNode }) => {
    const base = useTheme();
    const { theme } = useThemeContext();
    const nav = colorThemes[theme];
    if (nav === undefined) {
        return props.children;
    }
    const res = createTheme({
        ...base,
        palette: { ...base.palette, nav },
    });
    return <ThemeProvider theme={res}>{props.children}</ThemeProvider>;
};

interface NavPalette {
    background: string;
    selected: string;
    icon: string;
    text: string;
}

export const colorThemes: Record<string, NavPalette> = {
    midi: {
        background: grey[800],
        icon: amber[800],
        text: '#fff',
        selected: grey[700],
    },
    frost: {
        background: '#57667b',
        selected: '#648fac',
        icon: '#ECF0F1',
        text: '#FFFFFF',
    },
    evergreen: {
        background: '#004200',
        selected: '#16A085',
        icon: '#F1C40F',
        text: '#FFFFFF',
    },
    sakura: {
        background: '#ffe8ef',
        selected: '#fffcfe',
        icon: '#fd71a0',
        text: '#212121',
    },
    mariaRosa: {
        background: '#e13f7d',
        selected: '#fb67b1',
        icon: '#FFD1DC',
        text: '#FFFFFF',
    },
    ocean: {
        background: '#1B4F72',
        selected: '#2874A6',
        icon: '#AED6F1',
        text: '#FFFFFF',
    },
    sunset: {
        background: '#bb5023',
        selected: '#E67E22',
        icon: '#faab4c',
        text: '#FFFFFF',
    },
};
