import { Box, useTheme } from '@mui/material';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { common, createLowlight } from 'lowlight';
import { atomOneDark, atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Markdown } from 'tiptap-markdown';
import { ARTICLE_BASE_FONT_SIZE, ColorMode, articleHeaderStyles } from '../../../Theme';
import { FormText } from '../../../lib/Form';
import { applyFilters } from '../../../lib/Form/Filters';
import { useColorModeContext, useGateway } from '../../Context';
import ImageUpload from './ImageUploadExtension';
import { RichTextLink } from './RichTextLinkExtension';
import { TightList } from './TightListExtension';
import { Toolbar } from './Toolbar';

const lowlight = createLowlight(common);

const extensions = [
    StarterKit.configure({
        codeBlock: false,
    }),
    RichTextLink.configure({
        openOnClick: false,
    }),
    Table,
    TableRow,
    TableHeader,
    TableCell,
    CodeBlockLowlight.configure({
        lowlight,
    }),
    TightList,
    Markdown.configure({ transformPastedText: true, breaks: true }),
];

const removeSpace = (v: string) =>
    v
        .replace(/^[ \t]+\n/gm, '')
        .replace(/^\n\n/gm, '\n')
        .replace(/\\\n/g, '\n');

const getCodeStyles = (colorMode: ColorMode) => {
    const obj = colorMode === 'dark' ? atomOneDark : atomOneLight;
    return Object.keys(obj).reduce(
        (acc, key) => {
            acc[`.${key}`] = obj[key];
            return acc;
        },
        {} as Record<string, any>
    );
};

export const TiptapEditor: React.FC<{
    formText: FormText;
}> = (props) => {
    const { colorMode } = useColorModeContext();
    const theme = useTheme();
    const gateway = useGateway();
    const editor = useEditor({
        extensions: [...extensions, ImageUpload.configure({ inline: true, gateway })],
        content: props.formText.value,
        onUpdate: ({ editor }) => {
            props.formText.setValue(removeSpace(editor.storage.markdown.getMarkdown() as string));
        },
        onBlur: () => {
            // Apply filters to update the cache only,
            // the content will not update by filters.
            // And unfortunately, it won't work on React.StrictMode.
            // https://github.com/remirror/remirror/issues/1975
            props.formText.setValue(applyFilters(props.formText), props.formText.dirty);
        },
    });
    if (editor === null) {
        return null;
    }
    return (
        <Box
            sx={{
                '.ProseMirror': {
                    minHeight: '12rem',
                    padding: theme.spacing(0.5, 2),
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: '4px',
                },
                '.ProseMirror > p': {
                    margin: theme.spacing(2, 0),
                    fontSize: ARTICLE_BASE_FONT_SIZE,
                    fontFamily:
                        "Roboto, 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴシック Pro', sans-serif",
                    lineHeight: 1.75,
                },
                a: {
                    color: theme.palette.link,
                },
                img: {
                    maxWidth: '100%',
                },
                'img.ProseMirror-selectednode': {
                    outline: `3px solid ${theme.palette.info.light}`,
                },
                'li p': {
                    margin: 0,
                },
                blockquote: {
                    margin: 0,
                    paddingLeft: theme.spacing(1),
                    borderLeft: `4px solid ${theme.palette.divider}`,
                },
                pre: {
                    margin: theme.spacing(2, 0),
                    padding: theme.spacing(1, 2),
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: theme.palette.divider,
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.primary.dark,
                    whiteSpace: 'pre',
                    fontFamily: '"Ubuntu Mono", monospace',
                },
                code: {
                    fontFamily: '"Ubuntu Mono", monospace',
                    fontSize: '0.9rem',
                },
                '& :not(pre) > code': {
                    margin: theme.spacing(0, 0.5),
                    padding: theme.spacing(0.5),
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: theme.palette.divider,
                    backgroundColor: theme.palette.background.paper,
                },
                table: {
                    borderCollapse: 'collapse',
                },
                th: {
                    backgroundColor: theme.palette.background.paper,
                    textAlign: 'left',
                    fontWeight: 500,
                },
                'th, td': {
                    padding: theme.spacing(0.75, 2),
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: theme.palette.divider,
                },
                'th > p, td > p': {
                    margin: 0,
                    fontSize: '12px',
                },
                ...articleHeaderStyles(theme),
                ...getCodeStyles(colorMode),
            }}
        >
            <Toolbar editor={editor} />
            <Box mt={0.5}>
                <EditorContent editor={editor} />
            </Box>
        </Box>
    );
};
