import { env } from '@env';

const algorithm = 'AES-CBC';

export const decrypt = async (iv: string, text: string): Promise<string> => {
    const bytes = decodeBase64(text);
    try {
        const decrypted = await crypto.subtle.decrypt(
            { name: algorithm, iv: decodeBase64(iv) },
            await getCryptoKey(),
            bytes
        );
        return new TextDecoder().decode(decrypted);
    } catch {
        throw new Error('Failed to decrypt');
    }
};

export const encrypt = async (iv: string, text: string): Promise<string> => {
    try {
        const encrypted = await crypto.subtle.encrypt(
            { name: algorithm, iv: decodeBase64(iv) },
            await getCryptoKey(),
            new TextEncoder().encode(text)
        );
        return encodeBase64(new Uint8Array(encrypted));
    } catch {
        throw new Error('Failed to encrypt');
    }
};

export const decodeBase64 = (v: string): Uint8Array => {
    const bin = atob(v);
    const bytes = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
        bytes[i] = bin.charCodeAt(i);
    }
    return bytes;
};

export const encodeBase64 = (v: Uint8Array): string => btoa(String.fromCharCode(...v));

const getCryptoKey = (): Promise<CryptoKey> =>
    crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode(env.apiEncryptionKey),
        algorithm,
        true,
        ['encrypt', 'decrypt']
    );
